.skeletonContainer {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
}

.skeletonItem {
  margin-inline-end: var(--spacing-small);
  border-radius: var(--border-radius-pill);
  pointer-events: none;

  // force border-radius since the skeleton uses pseudo elements
  overflow: hidden;
}
