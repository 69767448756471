@use "src/styles/breakpoints";
@use "src/styles/theme";

$link-shadow: 0px 3px 11px 1px rgb(0 0 0 / 3%);

.header {
  margin-block-end: var(--spacing-xsmall);
  padding-inline-start: var(--flow-side-spacing, var(--spacing-small));
}

.quickLinksContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-x: auto;
  margin-inline: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 0;
  }
}

.link {
  margin-inline: var(--spacing-xxsmall);
  margin-block-end: var(--spacing-xxsmall);
  &:hover {
    box-shadow: var(--shadow-large);
    color: var(--color-text-inverse);
  }
}
