.container {
  display: flex;
  border-block-end: 1px solid var(--color-borders-hairline);
}

.tabItem {
  cursor: pointer;
  padding-block-start: var(--spacing-xxsmall);
  padding-block-end: var(--spacing-xxsmall);
  padding-inline-start: var(--spacing-xxsmall);
  padding-inline-end: var(--spacing-xxsmall);
  background-color: var(--color-background-default);
}
.tabItem + .tabItem {
  margin-inline-start: var(--spacing-xxsmall);
}

.tabItemSelected {
  border-block-end: 2px solid var(--color-text-default);
}
